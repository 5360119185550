<template>
    <b-card>
        <custom-loader :show="showLoader">
            <v-select v-if="editing" v-model="selected" @input="setSelection" appendToBody class="floating-filter w-100" :options="versions" :clearable="false" :searchable="false" label="id" :reduce="item => item">
                <template #selected-option="{id,version_timestamp}">
                    <span  class="textCutSelect">
                        {{$t('version.current')}} - {{0 > getIndexById(id)  ? versions.length === 0 ? versions.length : versions.length-1  : versions.length - getIndexById(id)-1}}
                        ({{ $dayjs(version_timestamp).format('DD.MM.YYYY HH:mm:ss') }})
                    </span>
                </template>
                <template #option="{id,version_timestamp}">
                    <span  class="textCutSelect">
                        {{$t('version.version')}} - {{versions.length - getIndexById(id) -1 }}
                        ({{ $dayjs(version_timestamp).format('DD.MM.YYYY HH:mm:ss') }})
                    </span>
                </template>
            </v-select>
          
            <div v-if="!showLoader">
                <div class="mb-1 w-100 p-2 d-flex justify-content-end mt-3" v-if="moveSupply && editing ">
                    <b-button variant="primary" @click="viewReportModal">
                        {{ $t('general.view_printout') }}
                    </b-button>
                </div>
                <div class="col-12 col-md-6">
                    <div class="data-border">
                        <div class="border-text">
                            {{ $t('material.move_supplies.move_supply_data') }}
                        </div>

                        <!-- PODATKI O PREMIKU ZALOGE -->
                        <div class="p-1">
                            <validation-observer ref="validation">
                                <div class="row">
                                    <!-- IZ SKLADISCA -->
                                    <div class="col-6">
                                        <b-form-group :label="$t('material.move_supplies.from_warehouse')">
                                            <div v-if="addObject && moveSupply && editing  && addObject.from !== moveSupply.from" class="pb-1" style="text-decoration: line-through">
                                                <v-select v-model="moveSupply.from" :disabled="true" appendToBody label="name" :options="warehouses" :clearable="false" :reduce="item => item.id"/>
                                            </div>

                                            <validation-provider #default="{ errors }" :name="$t('material.move_supplies.from_warehouse')" rules="required">
                                                <v-select v-model="addObject.from" appendToBody label="name" :options="warehouses" :clearable="false" :reduce="item => item.id" :selectable="ele => ele.id !== addObject.to" @input="updateTemporaryMoveSupply"/>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </div>
                                    <!-- V SKLADISCE -->
                                    <div class="col-6">
                                        <b-form-group :label="$t('material.move_supplies.to_warehouse')">
                                            <div v-if="addObject && moveSupply && editing  && addObject.to !== moveSupply.to" class="pb-1" style="text-decoration: line-through">
                                                <v-select v-model="moveSupply.to" :disabled="true" :clearable="false" appendToBody label="name" :options="warehouses" :reduce="item => item.id"/>
                                            </div>

                                            <validation-provider #default="{ errors }" :name="$t('material.move_supplies.to_warehouse')" rules="required">
                                                <v-select v-model="addObject.to" appendToBody label="name" :options="warehouses" :reduce="item => item.id" :selectable="ele => ele.id !== addObject.from" :disabled="!addObject.from" @input="updateTemporaryMoveSupply"/>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <!-- IZBIRA TIPA CASA -->
                                    <div class="col-12">
                                        <b-form-group>
                                            <div v-if="moveSupply && editing  && selectTime !== selectTimeOriginal" class="pb-1">
                                                <b-form-radio-group v-model="selectTimeOriginal" :disabled="true" :options="optionsTime"/>
                                            </div>

                                            <validation-provider #default="{ errors }" :name="$t('general.start_time')" rules="required">
                                                <b-form-radio-group v-model="selectTime" :options="optionsTime" name="select time" @change="updateTemporaryMoveSupply"/>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <!-- IZBIRA CASA -->
                                    <div class="col-12">
                                        <b-form-group :label="$t('general.date')">
                                            <div v-if="addObject && moveSupply && editing  && addObject.timestamp !== moveSupply.timestamp" class="pb-1" style="text-decoration: line-through">

                                                <input class="form-control"
                                                       :value="formatDate(moveSupply.timestamp)"
                                                       :disabled="true"
                                                />
                                            </div>

                                            <validation-provider #default="{ errors }" :name="$t('general.date')" rules="required">
                                                <date-picker :first-day-of-week="2" v-model="addObject.timestamp" :masks="{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'}" :model-config="modelConfig" is24hr hide-time-header :mode="selectedMode"  :is-required="true" @input="updateTemporaryMoveSupply">
                                                    <template v-slot="{ inputValue, inputEvents }">
                                                        <input class="form-control"
                                                               :value="formatDate(inputValue)"
                                                               v-on="inputEvents"
                                                        />
                                                    </template>
                                                </date-picker>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </div>
                                </div>

                            </validation-observer>

                            <div v-if="moveSupply && editing  && differenceInData" class="d-flex p-1 justify-content-end">
                                <b-button variant="warning" @click="revertMoveSupplyDataChanges">{{ $t('general.revert') }}</b-button>
                            </div>

                        </div>
                    </div>
                </div>

                <div v-if="!infoProvided" class="p-2 d-flex justify-content-end">
                    <b-button variant="primary" @click="finishedEditing(false)">
                        {{ $t('general.next_step') }}
                    </b-button>
                </div>

                <!-- GUMBI TABELE -->
                <div v-if="infoProvided">
                    <div class="my-2 p-2 d-flex justify-content-between align-items-center">
                        <div>
                            <h4 class="card-title my-auto">{{ $t('general.ingredients') }}</h4>
                        </div>
                        <div class="d-flex justify-content-end">
                            <div v-if="moveSupply && editing && !compareItemsArray(this.moveSupply.items, this.addObject.items)" class="pr-2">
                                <b-button variant="warning" @click="revertTableChanges">{{ $t('general.revert') }}</b-button>
                            </div>
                            <div>
                                <b-button variant="primary" @click="openModalAddProduct">{{ $t('general.add_ingredient') }}</b-button>
                            </div>
                        </div>
                    </div>

                    <!-- TABELA -->
                    <div v-if="joinedTable && averagePrices.length !== 0" class="mt-2 p-2 mb-2">
                        <Table ref="itemsTable" :columnDefs="columnDefs" :rowData="joinedTable" @deleteRow="removeItemFromTable" @editRow="editItem"/>
                    </div>

                    <!-- POVZETEK CEN -->
                    <div class="col-12 col-md-6">
                        <div class="data-border">
                            <div class="border-text">
                                {{ $t('material.move_supplies.average_price') }}
                            </div>
                            <div class="row p-1">
                                <div class="col-6 text-price">
                                    {{ $t('material.total_price') }}:
                                </div>
                                <div class="col-6 text-price-bold text-right">
                                    {{$numberRounding(this.totalPrice)}}€
                                </div>
                                <!-- PREJSNJA CENA -->
                                <div v-if="totalPriceOriginal && $numberRounding(totalPriceOriginal) !== $numberRounding(totalPrice)" class="col-12 text-right" style="color: #e74c3c; text-decoration: line-through;">
                                    {{$numberRounding(this.totalPriceOriginal)}}€
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- GUMBI ZA SHRANJEVANJE PREMIKA -->
                    <div class="p-2 d-flex justify-content-between">
                        <div class="d-flex justify-content-center">
                            <div class="pr-2">
                                <b-button variant="danger" @click="deleteMoveSupply">
                                    <span class="align-middle">{{ $t('general.delete') }}</span>
                                </b-button>
                            </div>
                            <div v-if="moveSupply && editing " class="pr-2">
                                <b-button variant="warning" @click="discardChangesMoveSupply">
                                    <span class="align-middle">{{ $t('general.discard') }}</span>
                                </b-button>
                            </div>
                        </div>
                        <b-button variant="success" @click="finishedEditing(true)" :disabled="!allowConfirm">
                            <span v-if="moveSupply && editing " class="align-middle">{{ $t('general.accept') }}</span>
                            <span v-else class="align-middle">{{ $t('material.move_supplies.finish_move_supply') }}</span>
                        </b-button>
                    </div>
                </div>

                <report-preview-modal ref="moveSupplyReportModal" />
                <add-item-to-write-off-or-move-supply ref="addItemToMoveSupply" @addWriteOffItem="addItemToMoveSupply" :used-ingredients="usedIngredients" :average-prices="averagePrices"></add-item-to-write-off-or-move-supply>
            </div>
        </custom-loader>
    </b-card>
</template>

<script>
    import {BButton, BFormGroup, BFormRadioGroup, BCard} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required } from '@validations'
    import Table from '@/views/components/Table/Table.vue'
    import {DatePicker} from 'v-calendar'
    import AddItemToWriteOffOrMoveSupply from '@/views/components/AddItemToWriteOffOrMoveSupply.vue'
    import * as Sentry from '@sentry/vue'
    import ReportPreviewModal from '@/views/components/ReportPreviewModal.vue'
    import CustomLoader from '@/components/CustomLoader.vue'
    import {
        getMoneyTypes,
        getPieceTypes,
        getVolumeTypes,
        getWeightTypes
    } from '@/libs/constants/ingredients.js'
    import {getOptionsTimeSelect} from '@/libs/constants/material.js'

    export default {
        components: {
            CustomLoader,
            ReportPreviewModal,
            AddItemToWriteOffOrMoveSupply,
            Table,
            ValidationProvider,
            ValidationObserver,
            BButton,
            BFormRadioGroup,
            vSelect,
            BFormGroup,
            BCard,
            DatePicker
        },
        data() {
            return {
                apiUrl: '',
                fileName: '',
                showLoader: true,
                modalActive: true,
                moveSupply: null,
                confirmed: true,
                editingIngredientId: null,
                editing: false,
                addObject: {
                    from: '',
                    to: '',
                    timestamp: new Date().toISOString(),
                    items: []
                },
                required,
                selectTime: true,
                selectTimeOriginal: true,
                warehouses: [],
                averagePrices: [],
                infoProvided: false,
                totalPrice: 0,
                totalPriceOriginal: 0,
                modelConfig: {
                    type: 'string',
                    mask: 'iso'
                },
                usedIngredients: [],
                versions:[],
                selected:{id:''},
                moveSupplyId:'',
                finished:false,
                allowConfirm:true
            }
        },
        computed: {
            money_types() {
                return getMoneyTypes()
            },
            weight_types() {
                return getWeightTypes()
            },
            volume_types() {
                return getVolumeTypes()
            },
            piece_types() {
                return getPieceTypes()
            },
            optionsTime() {
                return getOptionsTimeSelect()
            },
            columnDefs() {
                return [
                    {
                        headerName: this.$t('table_fields.name'),
                        editable: false,
                        field: 'ingredient_id',
                        filter: true,
                        cellRenderer: (params) => this.getFieldIngredientName(params.data)
                    },
                    {
                        headerName: this.$t('table_fields.quantity'),
                        editable: false,
                        field: 'quantity',
                        filter: true,
                        cellRenderer: (params) => this.getFieldQuantity(params.data)
                    },
                    {
                        headerName: this.$t('table_fields.packaging'),
                        editable: false,
                        field: 'packaging',
                        filter: true,
                        cellRenderer: (params) => this.getFieldPackaging(params.data)
                    },
                    {
                        headerName: this.$t('table_fields.actions'),
                        editable: false,
                        filter: false,
                        sortable: false,
                        colId: 'actions',
                        cellRenderer: 'btnCellRenderer',
                        cellRendererParams: {removeObject: true},
                        minWidth: 150
                    }
                ]
            },
            columns() {
                return [
                    {
                        headerName: this.$t('table_fields.name'),
                        editable: false,
                        field: 'ingredient_id',
                        filter: true,
                        cellRenderer: (params) => this.getIngredientName(params.value)
                    },
                    {headerName: this.$t('table_fields.quantity'), editable: false, field: 'quantity', filter: true},
                    {
                        headerName: this.$t('table_fields.packaging'),
                        editable: false,
                        field: 'packaging',
                        filter: true,
                        cellRenderer: (params) => this.getPackaging(params.data)
                    }
                ]
            },
            differenceInData() {
                if (!this.moveSupply || !this.addObject) {
                    return false
                }
                return !(this.addObject.to === this.moveSupply.to &&
                    this.addObject.from === this.moveSupply.from &&
                    this.selectTime === this.selectTimeOriginal &&
                    this.addObject.timestamp === this.moveSupply.timestamp)
            },
            compareData() {
                if (!this.moveSupply || !this.addObject) {
                    return false
                }

                return this.compareItemsArray(this.moveSupply.items, this.addObject.items) && this.addObject.to === this.moveSupply.to && this.addObject.from === this.moveSupply.from
            },
            selectedMode() {
                if (!this.selectTime) {
                    return 'dateTime'
                } else {
                    return 'date'
                }
            },
            joinedTable() {
                const resultArray = []

                const addObjectMap = new Map(this.addObject.items.map(item => [item.ingredient_id, item]))

                for (const moveSupplyItem of this.moveSupply.items) {

                    const addObjectItem = addObjectMap.get(moveSupplyItem.ingredient_id)

                    if (addObjectItem) {
                        if (this.objectsDiffer(moveSupplyItem, addObjectItem)) {
                            resultArray.push({ ...addObjectItem, temporary_changes: 2 })
                        } else {
                            resultArray.push({ ...addObjectItem, temporary_changes: 3 })
                        }
                    } else {
                        resultArray.push({ ...moveSupplyItem, temporary_changes: 1 })
                    }
                }

                if (this.addObject.items.length !== 0) {
                    for (const addObjectItem of this.addObject.items) {

                        const existingIndex = resultArray.findIndex(item => item.ingredient_id === addObjectItem.ingredient_id)

                        if (existingIndex === -1) {
                            resultArray.push({ ...addObjectItem, temporary_changes: 0 })
                        }
                    }
                }

                return resultArray.length ? resultArray : []
            },
            selectedColumns() {
                if (this.moveSupply && !this.editing) {
                    return this.columns
                } else {
                    return this.columnDefs
                }
            }
        },
        methods: {
            compareItemsArray(arr1, arr2) {
                if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false
                if (arr1.length !== arr2.length) return false
                return arr1.every((item, index) => item === arr2[index])
            },
            formatDate(inputDate) {
                if (!this.selectTime) {
                    return this.dayjs(inputDate).format('dddd, DD.MM.YYYY, HH:mm')
                } else {
                    return this.dayjs(inputDate).format('dddd, DD.MM.YYYY')
                }
            },
            async revertTableChanges() {
                this.addObject.items = JSON.parse(JSON.stringify(this.moveSupply.items))
                await this.updateTemporaryMoveSupply()

                this.usedIngredients = []
                for (const item of this.addObject.items) {
                    this.usedIngredients.push(item.ingredient_id)
                }
                this.totalPrice = this.calcTotalPriceWhileChanging(this.totalPrice, this.addObject.items)
                this.$emit('refresh')
            },
            async viewReportModal() {
                await this.$refs.moveSupplyReportModal.show(this.fileName, this.apiUrl)
            },
            getFieldQuantity(data) {
                return this.checkField(data, undefined, 'quantity')
            },
            getFieldIngredientName(data) {
                return this.checkField(data, this.getIngredientName, 'ingredient_id')
            },
            getFieldPackaging(data) {
                return this.checkField(data, this.getPackaging, 'packaging')
            },
            checkField(data, fn, fieldName) {
                let show = fn ? fn(data[fieldName]) : data[fieldName]
                if (fieldName === 'packaging') {
                    show = fn(data)
                }

                if (this.moveSupply.items.length === 0) { // ustvarjamo nov move_supply
                    return `<div>${show}</div>`
                } else if (!this.moveSupply.items.some(obj => obj.ingredient_id === data.ingredient_id)) { // objekta ni v originalnem arrayu
                    return `<div style="color: #2ecc71;">${show}</div>`
                } else if (this.moveSupply.items.some(obj => obj.ingredient_id === data.ingredient_id) && this.addObject.items.some(obj => obj.ingredient_id === data.ingredient_id)) { // objekt obstaja v originalnem arrayju in v temporary

                    const temporaryItem = this.addObject.items.find(obj => obj.ingredient_id === data.ingredient_id)
                    const originalItem = this.moveSupply.items.find(obj => obj.ingredient_id === data.ingredient_id)

                    let original = fn ? fn(originalItem[fieldName]) : originalItem[fieldName]
                    let temporary = fn ? fn(temporaryItem[fieldName]) : temporaryItem[fieldName]

                    if (fieldName === 'packaging') {
                        original = fn(originalItem)
                        temporary = fn(temporaryItem)
                    }

                    if (originalItem[fieldName] !== temporaryItem[fieldName]) { // vrednosti sta dejansko drugačni
                        return `<div>
                                    <span style="color: #e74c3c; text-decoration: line-through;">${original}</span>
                                    <span style="color: #2ecc71;">${temporary}</span>
                                </span>`
                    } else { // vrednosti sta enaki
                        return `<div>${original}</div>`
                    }
                } else if (this.moveSupply.items.some(obj => obj.ingredient_id === data.ingredient_id) && !this.addObject.items.some(obj => obj.ingredient_id === data.ingredient_id)) { // objekt obstaja v original, ampak ne v temporary
                    const originalItem = this.moveSupply.items.find(obj => obj.ingredient_id === data.ingredient_id)
                    let original = fn ? fn(originalItem[fieldName]) : originalItem[fieldName]
                    if (fieldName === 'packaging') {
                        original = fn(originalItem)
                    }

                    return `<div style="color: #e74c3c; text-decoration: line-through;">${original}</div>`
                }
            },
            objectsDiffer(obj1, obj2) {
                return obj1.quantity !== obj2.quantity || obj1.stock !== obj2.stock || obj1.packaging !== obj2.packaging || obj1.packaging_unit !== obj2.packaging_unit
            },
            async updateTemporaryMoveSupply() {
                try {
                    const ingredients = this.addObject.items.map(obj => ({
                        amount: obj.packaging * obj.quantity,
                        ingredient_id: obj.ingredient_id,
                        packaging: obj.packaging,
                        packaging_unit: obj.packaging_unit,
                        quantity: obj.quantity
                    }))

                    const payload = {
                        'is_start_working_hour': this.selectTime,
                        'from': this.addObject.from,
                        'to': this.addObject.to,
                        'timestamp': this.addObject.timestamp,
                        ingredients,
                        'edited':true
                    }
                    this.showLoader = true
                    await this.$http.post(`/api/client/v1/move_supplies_temporary/${this.$route.params.id}`, payload)
                    await this.getAveragePricesForWarehouse()


                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                } finally {
                    this.showLoader = false
                }
            },
            async deleteMoveSupply() {
                const confirmDelete = await this.$confirmDialog(this.$t('material.move_supplies.confirm.delete_move_supply'))
                if (!confirmDelete) return
                try {

                    await this.$http.delete(`/api/client/v1/warehouses/move_supply/${this.$route.params.id}`)

                    this.$printSuccess(this.$t('print.success.delete'))

                    await this.$router.push({name: 'move_supplies'})
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            async discardChangesMoveSupply() {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_temporary_msg'))
                    if (!confirmDelete) return

                    await this.$http.delete(`/api/client/v1/move_supplies_temporary/${this.$route.params.id}`)

                    this.$printSuccess(this.$t('print.success.discarded_changes_msg'))

                    await this.$router.push({name: 'move_supplies'})
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.discarded_changes_delete_msg'))
                }
            },
            async revertMoveSupplyDataChanges() {
                this.selectTime = this.selectTimeOriginal
                this.addObject.from = this.moveSupply.from
                this.addObject.to = this.moveSupply.to
                this.addObject.timestamp = this.moveSupply.timestamp

                await this.updateTemporaryMoveSupply()
            },
            async getAveragePricesForWarehouse() {
                try {
                    const payload = {
                        timestamp: this.addObject.timestamp
                    }

                    if (this.addObject.from !== '') {
                        const response = await this.$http.post(`/api/client/v1/ingredients/average_price/${this.addObject.from}`, payload)
                        this.averagePrices = response.data ?? []

                        this.totalPrice = this.calcTotalPriceWhileChanging(this.totalPrice, this.addObject.items)
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            async loadData() {
                try {
                    const response = await this.$http.get('/api/client/v1/warehouses/')
                    this.warehouses = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            openModalAddProduct() {
                this.$refs.addItemToMoveSupply.open(null)
            },
            calcTotalPriceWhileChanging(totalPrice, items) {
                if (items) {
                    totalPrice = 0

                    for (let i = 0; i < items.length; i++) {
                        const item = items[i]
                        const averagePrice = this.averagePrices.find(obj => obj.id === item.ingredient_id)

                        if (averagePrice) {
                            totalPrice += averagePrice.average_price * item.packaging * item.quantity
                        }
                    }
                }
                return totalPrice
            },
            addItemToMoveSupply(payload, edited) {
                const itemCopy = JSON.parse(JSON.stringify(payload))
                if (edited) {
                    const index = this.addObject.items.findIndex(ele => payload.ingredient_id === ele.ingredient_id)

                    if (index > -1) { // najde objekt
                        this.addObject.items[index] = itemCopy

                        const usedIngredientsIndex = this.usedIngredients.indexOf(payload.ingredient_id)
                        if (usedIngredientsIndex > -1) {
                            this.usedIngredients[usedIngredientsIndex] = payload.ingredient_id
                        }

                        this.addObject.items.push() // to je zato da forciram joinedTable() da se pokliče
                        this.$refs.itemsTable.refresh()
                    } else { // spremenili smo že obstoječo sestavino v drugo sestavino
                        const indexToRemove = this.addObject.items.findIndex(item => item.ingredient_id === this.editingIngredientId)

                        if (indexToRemove > -1) {
                            this.addObject.items.splice(indexToRemove, 1)
                        }

                        this.addObject.items.push(itemCopy)
                        this.usedIngredients.push(payload.ingredient_id)
                    }
                } else {
                    this.usedIngredients.push(payload.ingredient_id)
                    this.addObject.items.push(itemCopy)
                }

                this.updateTemporaryMoveSupply()
                this.totalPrice = this.calcTotalPriceWhileChanging(this.totalPrice, this.addObject.items)
                if (this.editing) {
                    this.$emit('refresh')

                }
            },
            async removeItemFromTable(data) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    const index = this.addObject.items.findIndex(ele => ele.ingredient_id === data.ingredient_id)
                    if (index > -1) {
                        this.addObject.items.splice(index, 1)
                        this.usedIngredients.splice(index, 1)
                        this.$refs.itemsTable.refresh()
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                } finally {
                    this.totalPrice = this.calcTotalPriceWhileChanging(this.totalPrice, this.addObject.items)
                    await this.updateTemporaryMoveSupply()
                    this.$emit('refresh')

                }
            },
            async editItem(data) {
                this.editingIngredientId = data.ingredient_id
                await this.$refs.addItemToMoveSupply.open(data)
                this.totalPrice = this.calcTotalPriceWhileChanging(this.totalPrice, this.addObject.items)
            },
            async checkValidation() {
                return await this.$refs.validation.validate()
            },
            async finishedEditing(done) {
                const validation = this.checkValidation()

                if (validation) {
                    this.addObject.is_start_working_hour = this.selectTime === 0
                    this.infoProvided = true
                }

                if (!validation || !done) {
                    return
                }

                if (this.addObject.items.length === 0) {
                    this.$printError(this.$t('print.error.on_empty_move_supply'))
                    return
                }

                const confirmDelete = await this.$confirmDialog(this.$t('material.move_supplies.confirm.supply_move'))
                if (!confirmDelete) return

                try {
                    this.allowConfirm = false
                    await this.$http.post(`/api/client/v1/move_supplies_temporary/permanent/${this.$route.params.id}`)
                    this.allowConfirm = true
                    this.finished = true
                    this.$printSuccess(this.$t('print.success.add'))
                    this.$router.push({name: 'move_supplies'})
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            getIngredientName(value) {
                if (value && this.averagePrices) {
                    const tmp = this.averagePrices.find(x => x.id === value)

                    if (tmp) return tmp.name
                }

                return '/'
            },
            getPackaging(params) {
                let factor = 1
                let type = null
                if (params.packaging_unit === 'mL' || params.packaging_unit === 'dL' || params.packaging_unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === params.packaging_unit)
                } else if (params.packaging_unit === 'g' || params.packaging_unit === 'dag' || params.packaging_unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === params.packaging_unit)
                } else if (params.packaging_unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === params.packaging_unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === params.packaging_unit)
                }

                if (!type) {
                    return
                }

                factor = type.factor

                const packagingInDisplayedUnit = params.packaging / factor

                return `${packagingInDisplayedUnit  } ${  params.packaging_unit}`
            },
            getIndexById(id) {
                return this.versions.findIndex(ele => ele.id === id)
            },
            async setSelection() {
                if (!this.compareData) return this.$printError(this.$t('print.error.save_before_switch'))

                await this.openEditing(this.selected, this.selected, true)

            },
            async openEditing(data, moveSupply, editing) {
                this.showLoader = true
                this.editing = editing


                this.moveSupply = moveSupply
                this.moveSupply.items = []
                if (editing) { // ne delamo novega premika
                    this.selectTimeOriginal = this.moveSupply.is_start_working_hour

                    this.apiUrl = `/api/reports/v1/printout/move_supply/${  data.id  }`
                    this.fileName = `PremikMedSkladisci_${data.move_supply_code}.pdf`

                    this.addObject.items = data.ingredients
                    this.moveSupply.items = this.moveSupply.ingredients
                    // this.selectedColumns.splice(this.selectedColumns.length - 1, 0, { headerName: this.$t('general.changes'), editable: false, field: 'temporary_changes', cellRenderer: 'DisplayTemporaryEditing'})
                    const exists = this.selectedColumns.some(column => column.field === 'temporary_changes')

                    if (!exists) {
                        this.selectedColumns.splice(this.selectedColumns.length - 1, 0, {
                            headerName: this.$t('table_fields.changes'),
                            editable: false,
                            field: 'temporary_changes',
                            cellRenderer: 'DisplayTemporaryEditing'
                        })
                    }
                } else { // delamo nov premik
                    this.moveSupply.items = []
                    this.selectTimeOriginal = data.is_start_working_hour
                    this.addObject.items = []
                    this.totalPriceOriginal = undefined
                }

                if (data.from !== this.$NilObjectId) {
                    this.infoProvided = true
                    this.confirmed = data.confirmed

                    this.addObject.from = data.from
                    this.addObject.to = data.to
                    this.addObject.timestamp = data.timestamp

                    await this.getAveragePricesForWarehouse()

                    this.selectTime = data.is_start_working_hour

                    this.addObject.items = data.ingredients
                    this.totalPriceOriginal = this.calcTotalPriceWhileChanging(this.totalPriceOriginal, this.moveSupply.items)
                    this.usedIngredients = this.addObject.items.map(item => item.ingredient_id)
                    this.totalPrice = this.calcTotalPriceWhileChanging(this.totalPrice, this.addObject.items)
                }
                this.addObject.timestamp = data.timestamp
                this.showLoader = false
            },
            async loadVersions() {
                try {
                    const res = await this.$http.get(`/api/client/v1/move_supplies_history/${this.$route.params.id}`)
                    this.versions = res.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                }
            }
        },

        async mounted() {
            await this.loadData()
            this.moveSupplyId = this.$route.params.id
            await this.loadVersions()
        },
        async beforeDestroy() {


            try {
                const ingredients = this.addObject.items.map(obj => ({
                    amount: obj.packaging * obj.quantity,
                    ingredient_id: obj.ingredient_id,
                    packaging: obj.packaging,
                    packaging_unit: obj.packaging_unit,
                    quantity: obj.quantity
                }))

                const payload = {
                    'is_start_working_hour': this.selectTime,
                    'from': this.addObject.from,
                    'to': this.addObject.to,
                    'timestamp': this.addObject.timestamp,
                    ingredients,
                    'edited':false
                }
                if (this.finished) return
                await this.$http.post(`/api/client/v1/move_supplies_temporary/${this.moveSupplyId}`, payload)
            } catch (err) {
                Sentry.captureException(err)
                this.$printError(this.$t('print.error.on_add'))
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.data-border{
  border: 2px solid #646464;
  margin: 5px;
  padding: 10px;
  position: relative;
}

.border-text{
  position: absolute;
  top: -12px;
  background: white;
  padding: 0 15px;
  font-weight: bold;
}

.dark-layout .border-text{
  background: #3e3e3e;
}

.text-price-bold{
  font-weight: bold;
  font-size: 17px;
}

.text-price{
  font-size: 17px;
}

</style>
